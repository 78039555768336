<template>
  <div class=" bg-gray-200 h-full">

    <div class="text-gray-900">
      <div class="p-4  justify-center ">
        <h1 class="text-4xl text-darkblue">
          REGEX
        </h1>
        <span>“You must enter a valid email address format" </span>
      </div>
      <div class="p-4 flex">
        <h1 class="text-3xl font-bold">
          Email format Checker
        </h1>
      </div>
      <div class="pl-4">
        <h1 class="text-l font-bold">
          Mail
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hash" v-model="input" name="about" rows="4" maxlength="32"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <button type="button" v-on:click="checkFormat(input)"
                  class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
            Check Format
          </button>
        </div>
      </div>

      <div class="pl-4">
        <h1 class="text-l font-bold">
          Output
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hashed text" v-bind:readonly="true" v-model="result" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">Result</p>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "EmailFormatChecker", data() {
    return {
      input: '',
      result: '',

    }
  },
  methods: {
    checkFormat(password) {
      if (password.toString().length <= 64) {
        // IF password matches regex value fill textbox.
        this.result = !password.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? "INVALID FORMAT" : "VALID FORMAT :)";

      } else {
        this.result = "INVALID FORMAT LENGTH, MAX IS 64 CHARACTERS";

      }

    }
  }
}
</script>

<style scoped>

</style>