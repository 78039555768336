<template>


  <div class="flex-auto h-full">
    <div class="flex flex-col overflow-y-hidden overflow-x-hidden" v-if="!$auth.loading && !$auth.isAuthenticated">
      <div class="text-gray-600 flex flex-col">
        <div class="mx-5  min-h-screen grid place-content-evenly">

          <div
              class="bg-white py-8 px-10 text-center rounded-md shadow-lg transform -translate-y-20 sm:-translate-y-24 max-w-xs mx-auto"
              @click="$auth.loginWithRedirect({})">
            <h2 class="font-semibold text-2xl mb-6 ">Login</h2>
            <img class="w-32 h-32 object-cover rounded-full mx-auto shadow-lg"
                 src="https://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                 alt="User avatar">
            <p class="capitalize  relative text-2xl mt-1">{{ $auth.user.name }}</p>
            <button
                class="rounded-md bg-gradient-to-r from-blue-400 to-indigo-500 text-xl  pt-3 pb-4 px-8 inline hover:font-bold ">
              Sign in with Google!
            </button>
          </div>

        </div>

      </div>


    </div>
    <div v-else>
      <div
          class="rounded-md bg-gradient-to-r text-center from-blue-400 to-indigo-500 text-xl  pt-3 pb-4 px-8 inline">

        <div class="p-4 flex">
          <h1 class="text-3xl">

          </h1>
        </div>
        <p>{{ $auth.user.name }}</p> <img class="w-32 h-32 object-cover rounded-full mx-auto shadow-lg"
                                          :src="$auth.user.picture"
                                          alt="User avatar">

      </div>
      <!-- component -->
      <div class="">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto  lg:max-w-none">
            <h2 class="text-2xl font-extrabold text-gray-900">Topics</h2>

            <div class="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
              <div class="group relative">
                <div class="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.mckinsey.com%2F~%2Fmedia%2FMcKinsey%2FBusiness%2520Functions%2FOperations%2FOur%2520Insights%2FThe%2520expanding%2520role%2520of%2520design%2520in%2520creating%2520an%2520end%2520to%2520end%2520customer%2520experience%2FExpanding-role-of-design-1536x1536-400_Standard.ashx%3Fmw%3D1536%26car%3D72%3A35%26cq%3D50%26tco%3D400&f=1&nofb=1" alt="RBAC." class="w-full h-full object-center object-cover">
                </div>
                <h3 class="mt-6 text-sm text-gray-500">
                  <router-link :to="{ name: 'rbac'}">
                  <span class="absolute inset-0"></span>
                    RBAC
                  </router-link>
                </h3>
                <p class="text-base font-semibold text-gray-900">Role based authorization</p>
              </div>

              <div class="group relative">
                <div class="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RtCZknBN0ITogvQwIOBrDQHaEK%26pid%3DApi&f=1" alt="Crypto." class="w-full h-full object-center object-cover">
                </div>
                <h3 class="mt-6 text-sm text-gray-500">
                  <router-link :to="{ name: 'crypto'}">
                    <span class="absolute inset-0"></span>
                   Crypto
                  </router-link>
                </h3>
                <p class="text-base font-semibold text-gray-900">Encryption and Cryptography</p>
              </div>

              <div class="group relative">
                <div class="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img src="https://timebusinessnews.com/wp-content/uploads/captcha-2.jpg" alt="Captcha." class="w-full h-full object-center object-cover">
                </div>
                <h3 class="mt-6 text-sm text-gray-500">
                  <router-link :to="{ name: 'captcha'}">
                    <span class="absolute inset-0"></span>
                    Captcha
                  </router-link>
                </h3>
                <p class="text-base font-semibold pb-4 mb-4 px-4 py-4 text-gray-900">Completely Automated Public Turing test</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>


export default {
  name: "Overview",
  data() {
    return { schemas:[]}
    },
  components: {},
  methods: {

  }
};


</script>

<style lang="css" scoped>

</style>
