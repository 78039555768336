import ApiService from "./ApiService";
import { CreateSchema } from "@/interfaces/dataset.types";

export default class CreateSchemaService extends ApiService {

  constructor() {
    super();
    this.extendBaseUrl("/generate");
  }

  async createSchema(data: any, stimulus: string, difficulty: string, input: CreateSchema) {
    console.log({
      day: data,
      stimulus: stimulus,
      difficulty: difficulty,
      author: input.author,
      description: input.description,
      title: input.title
    });
    return await this.postRequest(`schema`, {
      day: data, stimulus: stimulus, difficulty: difficulty, author: input.author,
      description: input.description,
      title: input.title
    });
  }


}
