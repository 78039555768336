import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './tailwind.css'
import './styles.css'
import './index.css'
import ApiServices from '../src/utils/ApiService/ApiServices';
import ServiceFunc from '../src/utils/Service';
import  VueFormWizard from 'vue-form-wizard'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
// Import the plugin here
import { Auth0Plugin } from './auth'
import { domain, clientId, audience } from "./auth/auth_config.json"

Vue.config.productionTip = false

Vue.config.productionTip = true
Vue.use(VueFormWizard)
Vue.use(Buefy, {
  defaultIconPack: 'mdi'
});
// Import the Auth0 configuration

// Install the authentication plugin here

Vue.use(ServiceFunc(ApiServices, "$apiService"))
Vue.use(Auth0Plugin, {
  domain,
  audience,
  clientId,
  onRedirectCallback: (appState: any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');