import ApiService from "./ApiService";
import { CreateSchema } from "@/interfaces/dataset.types";

export default class CreateSchemaService extends ApiService {

  constructor() {
    super();
    this.extendBaseUrl("/schemas");
  }

  async get() {
    return this.getRequest(``).then(schemaReq => {
      /*    const exercises: any[] = [];

          schemaReq.data.forEach(function(arrayItem: any) {
              exercises.push(arrayItem.attributes);
          });*/
      console.dir(schemaReq);
      return schemaReq;
    });
  }


}
