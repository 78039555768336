export class User {
  sub = ""
  names = ""
  nickname = ""
  picture = ""
  updatedAt = ""
  email = ""
  emailVerified = false
  'https://liop.app/roles': Array<string>

  provider?: string
  id?: string

  givenName?: string
  familyName?: string
  locale?: string
  [key: string]: string | boolean | undefined | Array<string>

  constructor (auth0User: { [key: string]: string | boolean | undefined } | undefined) {
    if (!auth0User) return
    for (const key in auth0User) {
      this[key] = auth0User[key]
    }

    this.sub = auth0User.sub as string
    this.provider = this.sub.split('|')[0]
    this.id = this.sub.split('|')[1]
  }

  get roles() {
    return this['https://liop.app/roles'] ? this['https://liop.app/roles'] : []
  }
}