import {getInstance} from './auth'
import {NavigationGuard} from 'vue-router'


export const authGuard: NavigationGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {

        if (authService.isAuthenticated) {
            console.log(to.meta?.roleNeeded)

            console.log(authService.user["https://fitness.app/roles"])

            const roles = authService.user["https://fitness.app/roles"]
            if (roles) {
                if (typeof roles !== "boolean") {
                    console.log(roles.indexOf(to.meta.roleNeeded))
                }
                console.log(roles)

                if (
                    !(

                        to.meta?.roleNeeded && typeof roles !== "boolean" && roles.includes(to.meta.roleNeeded)
                    )
                ) {
                    return next('/');
                }
            }
            return next();
        }

        authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    const unwatch = authService.$watch('loading', (loading: boolean) => {
        if (loading === false) {
            return fn();
        }
    });
};
