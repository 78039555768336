
import ApiService from "./ApiService";

export default class ExercisesApiService extends ApiService{

    constructor() {
        super();
        this.extendBaseUrl("/exercise");
    }

    async get() {
        return this.getRequest(``).then(ExerciseReq => {
        /*    const exercises: any[] = [];
            console.dir(ExerciseReq);
            ExerciseReq.data.forEach(function(arrayItem: any) {
                exercises.push(arrayItem.attributes);
            });*/
            return ExerciseReq;
        });
    }
    async getById(id: number) {
        return this.getRequest(``).then(ExerciseReq => {
            return ExerciseReq.filter((exercise: any) => {
                return exercise.muscleGroup[0].id === id;
            })
        });
    }


    async delete(productId: number) {
        return this.deleteRequest(`${productId}`);
    }

}