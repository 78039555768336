<template>
    <div class=" bg-gray-200 h-full">


        <div class="text-gray-900">
            <div class="p-4  justify-center ">
                <h1 class="text-4xl text-darkblue">
                    Key pair
                </h1>
                <span>Generate Key Pair</span>
            </div>

            <div class="pl-4">
                <h1 class="text-l font-bold">
                    Public Key
                </h1>
                <div class="sm:col-span-6">

                    <div class="mt-1">
            <textarea id="encrypted text" v-bind:readonly="true" v-model="publicKey" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">Public Key</p>
                </div>

            </div>


            <div class="pl-4">
                <h1 class="text-l font-bold">
                    Private Key
                </h1>
                <div class="sm:col-span-6">

                    <div class="mt-1">
            <textarea id="decrypted text" v-bind:readonly="true" v-model="privateKey" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">Private Key</p>
                </div>
            </div>
            <button type="button" v-on:click="generateKeyPair"
                    class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
                Generate Key Pair
            </button>
        </div>
    </div>
</template>

<script>
const { createDiffieHellman } = require('crypto');
    export default {
        name: "KeyPair", data() {
            return {
                publicKey: '',
                privateKey: ''
            }
        },
        methods: {
            generateKeyPair() {


              const diffHell = createDiffieHellman(60);

              diffHell.generateKeys('base64');
              console.log("Public Key : " ,diffHell.getPublicKey('base64'));
              console.log("Private Key : " ,diffHell.getPrivateKey('base64'));

              console.log("Public Key : " ,diffHell.getPublicKey('hex'));
              console.log("Private Key : " ,diffHell.getPrivateKey('hex'));

              this.privateKey = diffHell.getPrivateKey('hex')
                this.publicKey = diffHell.getPublicKey('hex');
            }
        }
    }
</script>

<style scoped>

</style>