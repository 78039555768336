import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LeftNav from "../LeftNav.vue";

import { authGuard } from "@/auth/authGaurd";
import Overview from "../components/Overview.vue";
import Captcha from "../components/Captcha.vue";
import Crypto from "../components/Crypto.vue";
import Trainers from "../components/Trainers.vue";
import Rbac from "../components/Rbac.vue";
import RbacUser from "../components/RbacUser.vue"
import Profile from "../views/Profile.vue";
import Regex from "../components/Regex.vue";
import Login from "@/views/Login.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [

  {
    path: "/callback",
    name: "callback",
    component: Overview
  },
  {
    path: "",
    name: "overview",
    component: Overview
  },
  {
    path: "/captcha",
    name: "captcha",
    beforeEnter: authGuard,
  meta: {
  roleNeeded: 'user',
},
    component: Captcha
  },
  {
    path: "/crypto",
    name: "crypto",
    beforeEnter: authGuard,
    meta: {
      roleNeeded: 'user',
    },

    component: Crypto

  },
  {
    path: "/rbac",
    name: "rbac",
    beforeEnter: authGuard,
    meta: {
      roleNeeded: 'admin',
    },
    component: Rbac
  },
  {
    path: "/rbacUser",
    name: "rbacUser",
    beforeEnter: authGuard,
    meta: {
      roleNeeded: 'user',
    },
    component: RbacUser
  },
  {
    path: "/regex",
    name: "regex",
    beforeEnter: authGuard,
    meta: {
      roleNeeded: 'user',
    },
    component: Regex
  },
  {
    name: "login",
    path: "/login",
    beforeEnter: authGuard,
    component: Overview
  },
  {
    name: "profile",
    path: "/profile",
    beforeEnter: authGuard,
    meta: {
      roleNeeded: 'user',
    },
    component: Profile
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
