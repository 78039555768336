import { VueAuth } from './../../auth/VueAuth';
import { getInstance } from '@/auth/auth';

export default abstract class ApiService {
    baseApiUrl = 'http://localhost:3000';
    _auth!: VueAuth;
    loaded = false;
    
    extendBaseUrl(extention: string){
        this.baseApiUrl += extention;
    }

    get auth() {
        if(this._auth){
            return this._auth;
        }
        this._auth = getInstance();
        return this._auth;
    }

    get token() {
        if(this.auth.isAuthenticated){            
            return (async () => {
                await this.waitForAuthLoading();
                return this.auth.getTokenSilently({});
            })();
        }
        return "";
    }

    async waitForAuthLoading() {
        return new Promise<void>((response) => {
            if (!this.auth.loading) {
                return response();
            }
            const unwatch: any = this.auth.$watch('loading', (loading: boolean) => {
                if (loading === false) {
                    unwatch();
                    return response()
                }
            })
        });
    }


    getRequest = async (url: string) => {
        await this.waitForAuthLoading();
        return fetch(`${this.baseApiUrl}/${url}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${await this.token}`,
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'true',
            } as HeadersInit,
        }).then((response) => {
            return (!response.ok ? Promise.reject(response.status) : response.json());
        });
    };
    
    postRequest = async (url: string, data: object) => {
        return fetch(`${this.baseApiUrl}/${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${await this.token}`,
                'Content-type': 'application/json',
            } as HeadersInit,
            body: JSON.stringify(data),
        }).then((response) => {
    
            return (!response.ok ? Promise.reject(response.status) : response.json());
        });
    };
    
    
    putRequest = async (url: string, data: object) => {
        return fetch(`${this.baseApiUrl}/${url}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${await this.token}`,
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'true',
            } as HeadersInit,
            body: JSON.stringify(data),
        }).then((response) => {
            return (!response.ok ? Promise.reject(response.status) : response);
        });
    };
    
    deleteRequest = async (url: string) => {
        await this.waitForAuthLoading();

        return fetch(`${this.baseApiUrl}/${url}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${await this.token}`,
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'true',
            } as HeadersInit,
        }).then((response) => {
            return (!response.ok ? Promise.reject(response.status) : response);
        });
    };
}