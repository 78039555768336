<template>
  <div class=" bg-gray-200 h-full">

    <div class="text-gray-900">
      <div class="p-4  justify-center ">
        <h1 class="text-4xl text-darkblue">
          REGEX
        </h1>

        <span>“Your password must have at least 8 characters, at least an upper case letter, an lowercase letter, a number and a symbol" </span>
      </div>
      <div class="p-4 flex">
        <h1 class="text-3xl font-bold">
          Password Checker
        </h1>
      </div>
      <div class="pl-4">
        <h1 class="text-l font-bold">
          Password
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <input  type="password" id="password" v-model="input" name="about" rows="4" maxlength="32"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md">
          </div>
          <button type="button" v-on:click="checkPassword(input)"
                  class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
            Check Password
          </button>
        </div>
      </div>

      <div class="pl-4">
        <h1 class="text-l font-bold">
          Output
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hashed text" v-bind:readonly="true" v-model="result" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">Result</p>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "PasswordCheck", data() {
    return {
      input: '',
      result: '',

    }
  },
  methods: {
    checkPassword(password) {
      if (password.toString().length <= 32) {
        // IF password matches regex value fill textbox.
        this.result = !password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/g) ? "INVALID PASSWORD" : "VALID PASSWORD :)";

      } else {
        this.result = "INVALID PASSWORD LENGTH, MAX IS 32 CHARACTERS";

      }

    }
  }
}
</script>

<style scoped>

</style>