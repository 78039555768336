<template>
    <div class=" bg-gray-200 h-full pl-8">



      <div class="mt-4">
        <button v-for="category in this.toggledList" v-on:click="changeCat(category.id)" type="button"
                class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          {{category.name}}
        </button>
      </div>
<Encryption v-if="currentCat === 0"></Encryption>
<Hash v-if="currentCat === 1"></Hash>
<KeyPair v-if="currentCat === 2"></KeyPair>


    </div>
</template>

<script>


    import Encryption from "@/components/Encryption";
    import Hash from "@/components/Hash";
    import KeyPair from "@/components/Keypair";
    export default {
        name: "Crypto",
        components: {KeyPair, Hash, Encryption},
        data() {
            return {
                currentCat:0,
                toggledList:[{id:0, name:'Encryption'},{id:1
                   , name:'Hash'},{id:2
                    ,name:'KeyPair',}]
            }
        },
        methods: {
            changeCat(id) {
              this.currentCat = id;

            },

        }
    }
</script>

<style scoped>

</style>