<template>
  <div>
    <span
        class="flex py-4">Protect your text by Encrypting and Decrypting any given text with a key that no one knows </span>

    <div class="p-4 flex">
      <h1 class="text-3xl font-bold">
        Encryption
      </h1>
    </div>
    <div class="pl-4">
      <h1 class="text-l font-bold">
        Text to Encrypt
      </h1>
      <div class="sm:col-span-6">

        <div class="mt-1">
            <textarea id="encrypt" v-model="encrypt" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
        </div>
        <button type="button" v-on:click="encryptAES(encrypt)"
                class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
          Encrypt
        </button>
      </div>
    </div>

    <div class="pl-4">
      <h1 class="text-l font-bold">
        Encrypted Text
      </h1>
      <div class="sm:col-span-6">

        <div class="mt-1">
            <textarea id="encrypted text" v-bind:readonly="true" v-model="encrypted" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
        </div>
        <p class="mt-2 text-sm text-gray-500">Encrypted text</p>
      </div>

    </div>
    <h1 class="text-3xl font-bold pt-8">
      Decryption
    </h1>
    <div class="pl-4 pt-8">
      <h1 class="text-l font-bold">
        Text to Decrypt
      </h1>
      <div class="sm:col-span-6">

        <div class="mt-1">
            <textarea id="decrypt" v-model="decrypt" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
        </div>
        <button type="button" v-on:click="decryptAES(decrypt)"
                class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
          Decrypt
        </button>
      </div>
    </div>
    <div class="pl-4">
      <h1 class="text-l font-bold">
        Decrypted Text
      </h1>
      <div class="sm:col-span-6">

        <div class="py-4">
            <textarea id="decrypted text" v-bind:readonly="true" v-model="decrypted" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
        </div>
        <p class="py-2 pb-6 text-sm text-gray-500">Decrypted text</p>
      </div>
    </div>
  </div>
</template>

<script>

const { createCipheriv, randomBytes, createDecipheriv } = require('crypto');
export default {
  name: "Encryption",
  data() {
    return {
      key: randomBytes(32),
      iv: randomBytes(16),
      decrypt: '',
      decrypted: '',
      encrypt: '',
      encrypted: ''
    }
  },
  methods: {
    encryptAES(text) {
      const cipher = createCipheriv('aes256', this.key, this.iv);

      this.encrypted = cipher.update(text, 'utf-8', 'hex') + cipher.final('hex');
    },
    decryptAES(text) {
      const decipher = createDecipheriv('aes256', this.key, this.iv);
      this.decrypted  = decipher.update(text, 'hex', 'utf-8') + decipher.final('utf-8');
    },
    /*   encryptAES(text) {


         const encrypted = this.CryptoJS.AES.encrypt(text, this.key, { iv: this.iv });
         this.CryptoJS.
             this.encrypted = encrypted.toString();
       },
       decryptAES(text) {

         const decrypted = this.CryptoJS.AES.decrypt(text, this.key, { iv: this.iv });
         this.decrypted = decrypted.toString(this.CryptoJS.enc.Utf8);
       }*/
  }
}
</script>

<style scoped>

</style>