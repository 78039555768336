<template>
  <div class="flex min-h-screen">
    <nav class="w-48 flex-shrink-0">

      <div class="flex-auto bg-black h-full ">
        <div class="flex flex-col overflow-y-auto overflow-x-hidden">
          <ul class="relative m-0 p-0 list-none h-full">
            <li class="text-white text-2xl p-1 w-full h-12 flex relative shadow-sm justify-center bg-gray-700 border-b-2 border-gray-700">
              ICS-7
<!--              <img src="./assets/images/ics.png" height="24" width="24">-->
            </li>
            <li class="text-white p-4 w-full flex relative shadow-sm justify-start bg-black  ">

              <div class="flex-auto my-1">
                <span class="justify-center flex">Secure search bar</span>
                <search-bar class="mt-4"></search-bar>
              </div>
            </li>
            <li class="p-4 w-full flex relative  shadow-sm">
              <div class="flex-auto my-1">
                <span class="text-white font-medium">Portfolio</span>
              </div>
            </li>

            <router-link :to="{ name: 'overview'}" replace  class="text-gray-400 flex relative px-8 hover:bg-gray-700 cursor-pointer">
              <div class="mr-4 my-auto">
                <svg class="fill-current h-5 w-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path></svg>
              </div>
              <div class="flex-auto my-1">
                <span>Overview</span>
              </div>
            </router-link>

            <router-link v-if="doesUserHaveRole('admin')"  :to="{ name: 'rbac'}" replace class="text-gray-400 flex relative px-8 hover:bg-gray-700 cursor-pointer">
              <div class="mr-4 my-auto">
                <svg class="fill-current h-5 w-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM7 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM19 3H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM7 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path></svg>
              </div>
              <div class="flex-auto my-1">
                <span>Admin Dashboard</span>
              </div>
            </router-link>
            <router-link v-if="doesUserHaveRole('user')"  :to="{ name: 'rbacUser'}" replace class="text-gray-400 flex relative px-8 hover:bg-gray-700 cursor-pointer">
              <div class="mr-4 my-auto">
                <svg class="fill-current h-5 w-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM7 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM19 3H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM7 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path></svg>
              </div>
              <div class="flex-auto my-1">
                <span>User Dashboard</span>
              </div>
            </router-link>


            <router-link :to="{ name: 'crypto'}" replace class="text-gray-400 flex relative px-8 hover:bg-gray-700 cursor-pointer">
              <div class="mr-4 my-auto">
                <svg class="fill-current h-5 w-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path></svg>            </div>
              <div class="flex-auto my-1">
                <span>Crypto</span>
              </div>
            </router-link>
            <router-link :to="{ name: 'regex'}" replace class="text-gray-400 flex relative px-8 hover:bg-gray-700 cursor-pointer">
              <div class="mr-4 my-auto">
                <svg class="fill-current h-5 w-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path></svg>            </div>
              <div class="flex-auto my-1">
                <span>Regex</span>
              </div>
            </router-link>

            <router-link  :to="{ name: 'captcha'}" replace class="text-gray-400 flex relative px-8 hover:bg-gray-700 cursor-pointer">
              <div class="mr-4 my-auto">
                <svg class="fill-current h-5 w-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.77 6.76L6.23 5.48.82 12l5.41 6.52 1.54-1.28L3.42 12l4.35-5.24zM7 13h2v-2H7v2zm10-2h-2v2h2v-2zm-6 2h2v-2h-2v2zm6.77-7.52l-1.54 1.28L20.58 12l-4.35 5.24 1.54 1.28L23.18 12l-5.41-6.52z"></path></svg>
              </div>
              <div class="flex-auto my-1">
                <span>Captcha</span>
              </div>
            </router-link>
          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>

import SearchBar from '@/SearchBar'
import {getInstance} from "@/auth";
let authService;
export default {
name: 'LeftNav',
  components: { SearchBar },
  beforeMount() {
     authService = getInstance();
  },
  methods: {
  doesUserHaveRole(roleNeeded)
    {
      return (authService.user["https://fitness.app/roles"].includes(roleNeeded))
    }
  }
}
</script>

<style scoped>
.router-link-exact-active{
  color: #FFD600;
  border-left: 4px solid !important;
  border-color: #FFD600;
}

</style>
