<template>
  <div id='app' >
    <div class="flex min-h-screen">
      <left-nav></left-nav>
      <div class="flex flex-col w-full">
        <top-nav></top-nav>
        <router-view></router-view>
      </div>

    </div>
  </div>
</template>

<script>

import TopNav from "@/TopNav";
import LeftNav from "@/LeftNav";
export default {
  name: 'app',
  components: {
    LeftNav,TopNav
  }
}
</script>
