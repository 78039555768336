
import ExercisesApiService from "./exercises.apiService";
import MuscleGroupsApiService from "@/utils/ApiService/musclegroups.apiService";
import CreateSchemaService from "@/utils/ApiService/create.schema.service.apiService";
import SchemaService from "@/utils/ApiService/schema.apiService";


export default class ApiServices{
    exercises = new ExercisesApiService();
    muscleGroups = new MuscleGroupsApiService();
    createSchema = new CreateSchemaService();
    schema = new SchemaService();
}