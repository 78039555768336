<template>
  <div class=" bg-gray-200 h-full">

    <div class="text-gray-900">
      <div class="p-4  justify-center ">
        <h1 class="text-4xl text-darkblue">
          Crypto
        </h1>
        <span>Protect your text by Hashing any given text </span>
      </div>
      <div class="p-4 flex">
        <h1 class="text-3xl font-bold">
          Hash
        </h1>
      </div>
      <div class="pl-4">
        <h1 class="text-l font-bold">
          Text to hash
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hash" v-model="hash" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <button type="button" v-on:click="hashText(hash)"
                  class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
            hash
          </button>
        </div>
      </div>

      <div class="pl-4">
        <h1 class="text-l font-bold">
          hashed Text
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hashed text" v-bind:readonly="true" v-model="hashed" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">hashed text</p>
        </div>

      </div>
    </div>

    <div class="text-gray-900">
      <div class="p-4  justify-center ">
        <h1 class="text-4xl text-darkblue">
          Crypto
        </h1>
        <span>Protect your text by Hashing + Salting any given text </span>
      </div>
      <div class="p-4 flex">
        <h1 class="text-3xl font-bold">
          Hash
        </h1>
      </div>
      <div class="pl-4">
        <h1 class="text-l font-bold">
          Text to hash + Salt
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hash + salt" v-model="hashSalt" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <button type="button" v-on:click="hashSaltText(hashSalt)"
                  class="inline-flex items-center justify-center px-4 my-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue">
            hash
          </button>
        </div>
      </div>

      <div class="pl-4">
        <h1 class="text-l font-bold">
          hashed Text With salt
        </h1>
        <div class="sm:col-span-6">

          <div class="mt-1">
            <textarea id="hashed text + salt" v-bind:readonly="true" v-model="hashedSalt" name="about" rows="4"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:w-4/5  md:w-2/3 lg:w-2/5 sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">hashed text + salt</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

const {createHash} = require('crypto');
const {randomBytes} = require('crypto');
const { createHmac } = require('crypto');
export default {
  name: "Hash", data() {
    return {
      hash: '',
      hashed: '',
      hashSalt: '',
      hashedSalt: ''
    }
  },
  methods: {
    hashText(input) {
      this.hashed = createHash('sha256').update(input).digest('base64');
    },
    hashSaltText(input) {

      const salt = randomBytes(16).toString('hex');

      this.hashedSalt =  createHmac('sha256', salt).update(input).digest('hex');
    }
  }
}
</script>

<style scoped>

</style>