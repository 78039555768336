import ApiService from "./ApiService";

export default class MuscleGroupsApiService extends ApiService {

  constructor() {
    super();
    this.extendBaseUrl("/muscle-group");
  }

  async get() {

    return this.getRequest(``).then(muscleGroups => {
      const muscleGroupsListObject: any[] = [];
      muscleGroups.data.forEach(function(arrayItem: any) {
        muscleGroupsListObject.push({ name: arrayItem.attributes.name, id: arrayItem.id });
      });
      return muscleGroupsListObject;
    });
  }
  async getWithExercisesSlot() {

    return this.getRequest(``).then(muscleGroups => {
      const muscleGroupsListObject: any[] = [];
      muscleGroups.data.forEach((arrayItem: any) => {
        muscleGroupsListObject.push({ name: arrayItem.attributes.name, id: arrayItem.id,exercises:[],uniqueId:this.CreateUUID()});
      });
      return muscleGroupsListObject;
    });
  }


  async delete(productId: number) {
    return this.deleteRequest(`${productId}`);
  }
  CreateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });}
}